import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLForm, GLFormComponentProps, GLGlobal, GLUtil, GLLocale, connect, StateType, CountryCode, PrimaryLanguageLocale } from "gl-commonui";
import { Row, Spin } from "antd-min";
import { GLRegistrationLocalizationHelper, lazyInject, TYPES, fmtMsg, UserType, VietnamRegistrationAgreementRole, RussiaPrivacyPolicyRoles } from '@app/util/index';
import { GSAdminLocale } from '@app/locales/localeid';
import { RegisterModelProps } from '@app/service/school/invitation';
import { getInfoByCode, registerUser } from '@app/states/school//inviting';
import { CreateUserRequestModel, IUserService } from '@app/service/users';
import { GLRegistrationLocalization, GLRegistrationModel } from '@app/components/register/Model';
import { GLUserRegistrationForm } from '@app/components/register/gl-registration-form';
import { LoginIdVerificationData, RegistrationVerification } from '@app/components/register/registration-verification/registration-verification';
import { RegisterSuccessPage } from './registersuccess';
import { InvitationStatus } from '@app/service/admin/acceptinvitation';
import "@app/components/register/index.less";
import './register.less';
import PrivacyPolicyModal from './privacypolicymodal';
import PrivacyPolicy from '@app/page/privacypolicy/privacypolicy';
import TermsAgreementModal from './termsagreementmodal';

interface RegisterProps extends RouteComponentProps<any> {
    getInfoByCode: (params, callback) => void;
    registerUser: (params, callback) => void;
}
interface RegisterStates {
    confirmDirty?: boolean;
    defaultName?: string;
    defaultEmail?: string;
    defaultPhone?: string;
    isManualInvitation?: boolean;
    disabled?: boolean;
    selectedTab: RegistrationTabs;
    loading: boolean;
    error?: string;
    countryCode?: string;
    isPrivacyPolicyAccepted?: boolean;
    userIpAddress?: string;
    invitationCodeType: string;
    isTermsAgreementAccepted?: boolean;
}

enum RegistrationTabs {
    basicInfo,
    verify,
    complete
}

@withRouter
@GLForm.create()
@connect(({ oidc: { loginInfo } }: StateType) => ({
    isSignin: loginInfo && loginInfo.loggedin
}), {
    getInfoByCode,
    registerUser
})
export class RegisterPage extends Component<RegisterProps & GLFormComponentProps, RegisterStates> {
    code = GLUtil.queryParse().code
    codeId
    invitationType
    codeInfo
    basicInfo: Partial<GLRegistrationModel> = {};
    @lazyInject(TYPES.IUserService)
    userService: IUserService    
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            loading: true,
            selectedTab: RegistrationTabs.basicInfo
        };
    }

    componentDidMount() {
        this.props.getInfoByCode({ invitationCode: this.code }, this.setInvitationInfo.bind(this));
    }

    setInvitationInfo(data) {
        if(data.invitationStatus !== InvitationStatus.Init) {
            this.setState({error: GSAdminLocale.RegistrationInvalidCode, loading: false});
            return;
        }
        this.codeInfo = data;
        this.codeId = data.id;
        this.invitationType = data.invitationType;
        this.basicInfo.email = data.inviteeEmail;
        if (data.enablePhoneRegistration) {
            this.basicInfo.phone = data.inviteePhone
                ? data.inviteePhone.replace(data.phoneCountryCode, "")
                : null;
            this.basicInfo.phoneCountryCode = data.inviteePhone
                ? data.phoneCountryCode
                : null;
        }
        this.basicInfo.userName = data.inviteeName;

        this.setState({
            defaultName: data.inviteeName,
            defaultEmail: data.inviteeEmail,
            defaultPhone: data.inviteePhone,
            isManualInvitation: data.isManualInvitation,
            loading: false,
            countryCode: data.countryCode ? data.countryCode.toUpperCase() : '',
            invitationCodeType: data.invitationCodeType,
        });
    }

    checkPassword(rule, value, callback) {
        const form = this.props.form;
        if (value && value !== form.getFieldValue(RegisterModelProps.password)) {
            callback(GLGlobal.intl.formatMessage({ id: GLLocale.FormPwdDiff }));
        } else {
            callback();
        }
    }

    checkConfirm(rule, value, callback) {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields([RegisterModelProps.confirmPassword], { force: true }, () => { });
        }
        callback();
    }

    handleConfirmBlur(e) {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    registerCallback = (resolve: () => void, reject: () => void, isFail?: boolean) => {
        if (isFail) {
            this.setState({ disabled: false });
            reject();
        } else {
            this.setState({selectedTab: RegistrationTabs.complete})
            resolve();
        }
    }

    createNewAccount = (
        emailVerificationData: LoginIdVerificationData,
        phoneVerificationData: LoginIdVerificationData
    ): Promise<void> => {
        
        const params: CreateUserRequestModel = {
            userName: this.basicInfo.userName,
            englishName: this.basicInfo.englishName,
            password: this.basicInfo.password,
            email:
                this.basicInfo.email && this.basicInfo.email.length && emailVerificationData
                    ? this.basicInfo.email
                    : null,
            phone:
                this.basicInfo.phone && this.basicInfo.phone.length && phoneVerificationData
                    ? this.basicInfo.phone
                    : null,
            phoneCountryCode:
                this.basicInfo.phone && this.basicInfo.phone.length
                    ? this.basicInfo.phoneCountryCode
                    : null,
            invitationCode: this.code,
            invitationCodeId: this.basicInfo.invitationCodeId,
            invitationCodeType: this.invitationType,
            emailVerificationCodeId: !emailVerificationData
                ? 0
                : emailVerificationData.verificationCodeId,
            emailVerificationCode: !emailVerificationData
                ? null
                : emailVerificationData.verificationCode,
            phoneVerificationCodeId: !phoneVerificationData
                ? 0
                : phoneVerificationData.verificationCodeId,
            phoneVerificationCode: !phoneVerificationData
                ? null
                : phoneVerificationData.verificationCode
        };
        return new Promise((resolve, reject) => {
            const self = this;
            const callback = (isFailed, verifiedUserId?) => {
                if ((this.state.countryCode === CountryCode.Russsia && (this.state.invitationCodeType === RussiaPrivacyPolicyRoles.SchoolAdmin || this.state.invitationCodeType === RussiaPrivacyPolicyRoles.Teacher || this.state.invitationCodeType === RussiaPrivacyPolicyRoles.SchoolTeacher || this.state.invitationCodeType === RussiaPrivacyPolicyRoles.SubstituteTeacher)) || this.state.countryCode === CountryCode.Korea) {
                    if (verifiedUserId) {
                        const params = {
                            userId: verifiedUserId.id,
                            ipAddress: this.state.userIpAddress
                        }
    
                        this.userService.savePrivacyPolicyAcceptanceInfo(params);
                    }
                }
                self.registerCallback(resolve, reject, isFailed);
            }

            this.props.registerUser({params}, callback);
        });
    }

    handleUserIpAddress(ipAddress) {
        this.setState({ userIpAddress: ipAddress })
    }

    getCookieValue = (name) => {
        const cookies = document.cookie.split(';');
        const nameEQ = name + "=";

        for (let cookie of cookies) {
            cookie = cookie.trim();
            if (cookie.startsWith(nameEQ)) {
                return cookie.substring(nameEQ.length);
            }
        }
        return null;
    }

    getlanguage = (cookieValue) => {
        let language = null;

        const pairs = cookieValue.split('|');
        for (let pair of pairs) {
            const [key, value] = pair.split('=');
            if (key === 'c') {
                language = value;
                break;
            }
        }
        return language;
    }

    openPrivacyPolicy() {
        if (this.state.countryCode === CountryCode.Russsia) {
            const cookieValue = this.getCookieValue("GrapeSeed.Culture");
            let language = this.getlanguage(cookieValue);

            if (language === PrimaryLanguageLocale.English || language === PrimaryLanguageLocale.Russian) {
                window.open(`${GLGlobal.authorityUrl()}/Copyright/PrivacyPolicyV2?region=${this.state.countryCode}&language=${language}`);
            }
        }
        window.open(`${GLGlobal.authorityUrl()}/Copyright/PrivacyPolicy`);
    }

    getPrivacyElement() {
        const text = GLGlobal.intl.formatMessage({ id: GSAdminLocale.PrivacyPolicy }, { link: "&&##" });
        const textArr = text.split("&&##");      
        return  <Row style={{ marginTop: "-5px", marginBottom: "20px" }}>
            {textArr[0]}<a onClick={() => this.openPrivacyPolicy()}>{textArr[1]}</a>{textArr[2]}
        </Row>
    }

    onBasicInfoNextClick = (data: GLRegistrationModel) => {
        this.basicInfo = data;
        this.setState({ selectedTab: RegistrationTabs.verify });
    }

    renderForm = () => {
        const registrationLocalization: GLRegistrationLocalization = GLRegistrationLocalizationHelper.getGLRegistrationLocalization();
        registrationLocalization.emailPhonePlaceHolder = fmtMsg({ id: GSAdminLocale.RegistrationEmailLabel });
        return <GLUserRegistrationForm
            invitationCodeType={this.invitationType}
            invitationCode={this.code}
            invitationCodeId={this.codeId}
            registrationLocalization={registrationLocalization}
            privacyElement={this.getPrivacyElement()}
            onNextClick={this.onBasicInfoNextClick.bind(this)}
            email={this.basicInfo.email}
            phone={this.basicInfo.phone}
            phoneCountryCode={this.basicInfo.phoneCountryCode}
            disableEmail={false}
            disablePhone={false}
            userName={this.basicInfo.userName}
            userEnglishName={this.basicInfo.englishName}
            enablePhoneRegistration={this.codeInfo ? this.codeInfo.enablePhoneRegistration : false}
            isPrivacyPolicyAccepted={this.state.isPrivacyPolicyAccepted}
            invitationType={this.state.invitationCodeType}
            isTermsAgreementAccepted={this.state.isTermsAgreementAccepted}
            countryCode={this.state.countryCode}
        />;
    }

    changeTab = (tab: RegistrationTabs) => {
        if (
            tab === RegistrationTabs.basicInfo &&
            this.state.selectedTab === RegistrationTabs.verify
        ) {
            this.setState({ selectedTab: tab });
        }
    };

    renderTabs = () => {
        const tabs = [
            {
                key: RegistrationTabs.basicInfo,
                name: fmtMsg({
                    id: GSAdminLocale.RegistrationFormTabBasicInfo
                }),
                data: this.renderForm
            },
            {
                key: RegistrationTabs.verify,
                name: fmtMsg({
                    id: GSAdminLocale.RegistrationFormTabVerification
                }),
                data: () => (
                    <RegistrationVerification
                        emailId={this.basicInfo.email}
                        phone={this.basicInfo.phone}
                        countryCode={this.basicInfo.phoneCountryCode}
                        verifyEmail={true}
                        verifyPhone={true}
                        onBackClick={() =>
                            this.setState({
                                selectedTab: RegistrationTabs.basicInfo
                            })
                        }
                        afterVerify={this.createNewAccount}
                        enablePhoneRegistration={this.codeInfo ? this.codeInfo.enablePhoneRegistration : false}
                    />
                )
            },
            {
                key: RegistrationTabs.complete,
                name: fmtMsg({ id: GSAdminLocale.RegistrationFormTabComplete }),
                data: () => (
                    <RegisterSuccessPage email={this.basicInfo.email} phone={this.basicInfo.phone} history={this.props.history}/>
                )
            }
        ];

        const tabContent = tabs.find(f => f.key === this.state.selectedTab)
            .data;

        return (
            <div className="tab-wrap">
                <div className="tab-c">
                    {tabs.map((t, i) => {
                        const lastTabClass =
                            i === tabs.length - 1 ? "tab-t--last" : "";
                        const firstTabClass = i === 0 ? "tab-t--first" : "";
                        const selectedClass =
                            this.state.selectedTab === t.key
                                ? "tab-t--selected"
                                : "";
                        const cursorClass =
                            this.state.selectedTab ===
                                RegistrationTabs.verify &&
                            t.key === RegistrationTabs.basicInfo
                                ? "tab-t--p-cur"
                                : "tab-t--d-cur";
                        return (
                            <div
                                key={t.key}
                                className={`tab-t ${lastTabClass} ${firstTabClass} ${selectedClass} ${cursorClass}`}
                                onClick={() => this.changeTab(t.key)}
                            >
                                {t.name}
                            </div>
                        );
                    })}
                </div>
                {tabContent()}
            </div>
        );
    }

    handleIsPrivacyPolicyAccepted = (isPrivacyPolicyAccepted) => {
        this.setState({ isPrivacyPolicyAccepted: isPrivacyPolicyAccepted });
    }

    handleIsTermsAgreementAccepted = (isTermsAgreementAccepted) => {
        this.setState({ isTermsAgreementAccepted: isTermsAgreementAccepted });
    }

    render() {
        const { countryCode, invitationCodeType } = this.state
        const shouldShowTermsAgreemet = countryCode === CountryCode.Vietnam && (invitationCodeType === VietnamRegistrationAgreementRole.SchoolAdmin || invitationCodeType === VietnamRegistrationAgreementRole.Teacher || invitationCodeType === VietnamRegistrationAgreementRole.SchoolTeacher || invitationCodeType === VietnamRegistrationAgreementRole.SubstituteTeacher)
        if (this.state.loading) {
            return <Spin spinning={true} className="register-wait"/>
        }
        return <div>
            <Row className='registerHeader'>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.RegistrationSignUpHeader })}</Row>
            <Row className='register'>
                {this.state.error ? <div>{fmtMsg({id: this.state.error})}</div> : this.renderTabs()}
            </Row>
            <PrivacyPolicy userType={UserType.New} handleIsPrivacyPolicyAccepted={this.handleIsPrivacyPolicyAccepted.bind(this)} handleUserIpAddress={this.handleUserIpAddress.bind(this)} countryCode={countryCode} invitationType={invitationCodeType}></PrivacyPolicy>
            {shouldShowTermsAgreemet && <TermsAgreementModal handleIsTermsAgreementAccepted={this.handleIsTermsAgreementAccepted.bind(this)}  countryCode={countryCode} />}
        </div>
    }
}
