import React from 'react';
import { Modal, Button, Checkbox, Spin, Icon } from 'antd';
import request from 'superagent';
import { CountryCode, GLGlobal, MessageHelper, NotificationType, PrimaryLanguageLocale } from 'gl-commonui';
import "./termsagreementmodal.less"
import { SchoolLocale } from '@app/locales/localeid';
import { CountryToPrimaryLanguageLocaleMap } from '@app/util/consts';
import { TranslationText } from '@app/util';

interface TermsAgreementModalState {
    isModalVisible: boolean;
    checked: boolean;
    readMore: boolean;
    loading: boolean;
    htmlContent: any;
    isLanguageEnglish: boolean;
}

interface TermsAgreementModalProps {
    isPrivacyPolicyAccepted?: boolean;
    isTermsAgreementAccepted?: boolean;
    countryCode: string;
    handleIsTermsAgreementAccepted: (params) => void;
}

class TermsAgreementModal extends React.Component<TermsAgreementModalProps, TermsAgreementModalState> {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            checked: false,
            readMore: false,
            loading: false,
            htmlContent: '',
            isLanguageEnglish: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true, isModalVisible: true });
        this.fetchHtmlContent(CountryCode.Vietnam, PrimaryLanguageLocale.Vietnamese);
    }

    handleOk = async () => {
        this.props.handleIsTermsAgreementAccepted(true);
        this.setState({ isModalVisible: false });
    };

    handleCancel = () => {
        this.props.handleIsTermsAgreementAccepted(false);
        MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.TermsAgreementDeclineMessageForNewUser }));

        this.setState({ isModalVisible: false });
    };

    onCheckboxChange = (e) => {
        this.setState({ checked: e.target.checked });
    };

    changeLanguage = async () => {
        const { countryCode } = this.props;
        const primaryLanguageLocale = CountryToPrimaryLanguageLocaleMap[countryCode] || "en";        

        if (this.state.isLanguageEnglish === false) {
            await this.fetchHtmlContent(countryCode, PrimaryLanguageLocale.English);
        }
        else {
            await this.fetchHtmlContent(countryCode, primaryLanguageLocale);
        }

        this.setState((prevState) => ({ isLanguageEnglish: !prevState.isLanguageEnglish }));
    }

    async fetchHtmlContent(region: string, language: string) {
        await request.get(`${GLGlobal.authorityUrl()}/terms-agreement?region=${region}&language=${language}`).then(res => (res.text))
            .then(data => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(data, 'text/html');
                const bodyContent = doc.querySelector('.site-body').innerHTML;
                this.setState({ loading: false, htmlContent: bodyContent });
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }

    renderActionButtons = () => {
        const { checked } = this.state;

        return (
            <div className='action-btns'>
                <Button key="reject" type="danger" onClick={this.handleCancel} >
                    {GLGlobal.intl.formatMessage({ id: SchoolLocale.TermsAgreementDecline })}
                </Button>
                <Button key="accept" type="primary" onClick={this.handleOk} disabled={!checked} className={!checked ? "disable-accept-btn" : null}>
                    {GLGlobal.intl.formatMessage({ id: SchoolLocale.TermsAgreementAcceptBtn })}
                </Button>
            </div>
        );
    };

    
    renderTranslationButton = () => {
        const { isLanguageEnglish } = this.state;
        const enSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve"><circle cx="256" cy="256" r="256" fill="#F0F0F0"/><g fill="#D80027"><path d="M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87V256zM244.87 122.435h229.556a257.35 257.35 0 00-59.07-66.783H244.87v66.783zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.751 512 256 512zM37.574 389.565h436.852a254.474 254.474 0 0028.755-66.783H8.819a254.474 254.474 0 0028.755 66.783z"/></g><path fill="#0052B4" d="M118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.407 257.407 0 00-49.652 55.337h7.475l-13.813 10.035a255.58 255.58 0 00-6.194 10.938l6.596 20.301-12.306-8.941a253.567 253.567 0 00-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.468 258.468 0 000 256h256V0c-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422l-21.699-15.765L85.104 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zM220.328 230.4l-21.699-15.765L176.93 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm0-74.574l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765z"/></svg>);
        const viSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve"><circle cx="256" cy="256" r="256" fill="#D80027"/><path fill="#FFDA44" d="M256 133.565L283.628 218.594 373.033 218.594 300.702 271.144 328.33 356.174 256 303.623 183.67 356.174 211.298 271.144 138.968 218.594 228.372 218.594z"/></svg>);

        return (
            <div className='translation-button'>
                <Button type="default" onClick={this.changeLanguage}>
                    <div className='translation-content'>
                        <Icon component={isLanguageEnglish ? viSvg : enSvg } className="gl-logo-lang"/>
                        { isLanguageEnglish ? TranslationText.TranslateToVietnamese : TranslationText.TranslateToEnglish }
                    </div>
                </Button>
            </div>
        );
    };

    render() {
        const { loading, htmlContent } = this.state;
        
        return (
            <div>
                <Modal
                    visible={this.state.isModalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={860}
                    maskClosable={false}
                    closable={false}
                    footer={!loading ? [
                        <div className='checkbox-container'>
                            <Checkbox className='term-agreement' onChange={this.onCheckboxChange}>
                                        {GLGlobal.intl.formatMessage({ id: SchoolLocale.TermsAgreementAcceptance })}
                            </Checkbox>
                        </div>,
                        this.renderActionButtons()
                    ] : null}
                >
                    {!loading && this.renderTranslationButton()}                    
                    {loading ? <div className='spinner-container'><Spin /></div> :
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                        </div>
                    }
                </Modal>
            </div>
        );
    }
}

export default TermsAgreementModal;
